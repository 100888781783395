import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap } from '@react-google-maps/api';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { classModifier } from 'utils';
import { closeModal } from 'redux/ducks/activeWindows';
import { GirlsMapModalContext } from '../../GirlsMapModal';
import { CENTER_COORDINATES, MAP_SECONDARY_MODES } from 'redux/ducks/map';
import { selectMapGirlBySecondaryMode } from 'redux/selectors/selectors';

import ContactInfo from 'containers/Dialogs/components/ContactCard/components/ContactInfo/ContactInfo';
import Spinner from 'components/UI/Spinner/Spinner';
import { useDeepEffect } from 'hooks';


const GirlPreviewOnMap = () => {
  const panoramaContainerRef = useRef();
  const panoramaDivRef = useRef();
  const panoramaRef = useRef();

  const [navigationGirl] = useSelector(state => selectMapGirlBySecondaryMode(state, MAP_SECONDARY_MODES.TARGET_ESCORT));
  const [isStreetViewMapLoaded, setIsStreetViewMapLoaded] = useState(false);
  const [streetViewStatus, setStreetViewStatus] = useState(null);

  const { saveMapState } = useContext(GirlsMapModalContext)

  const dispatch = useDispatch();

  useDeepEffect(() => {
    setStreetViewStatus(null);

    if (isStreetViewMapLoaded && panoramaDivRef.current) {
      panoramaRef.current = new google.maps.StreetViewPanorama(panoramaDivRef.current, {
        position: { lat: Number(navigationGirl.girl.latitude), lng: Number(navigationGirl.girl.longitude) },
      });

      panoramaRef.current.addListener('status_changed', () => {
        const status = panoramaRef.current.getStatus();

        status && setStreetViewStatus(status);
      });
      
      setTimeout(() => {
        const status = panoramaRef.current.getStatus();

        status && setStreetViewStatus(status);
      }, 650)
    }
  }, [isStreetViewMapLoaded, navigationGirl])

  return (
    <>
      <div className="girls-map__content-preview" ref={panoramaContainerRef}>
        {navigationGirl?.girl ? (
          <>
            <GoogleMap
              onLoad={() => setIsStreetViewMapLoaded(true)}
              onUnmount={() => setIsStreetViewMapLoaded(false)}
              defaultCenter={CENTER_COORDINATES}
              defaultZoom={14}
            >
              {isStreetViewMapLoaded && (
                <div
                  ref={panoramaDivRef}
                  className="girls-map__street-view"
                />
              )}
            </GoogleMap>

            {streetViewStatus === 'ZERO_RESULTS' && (
              <div className={classModifier('girls-map__street-view', 'fallback')}>
                Street view is unavailable for this location
              </div>
            )}
          </>
        ) : (
          <Spinner spinnerSize={44} />
        )}
      </div>

      <div className="girls-map__content-contact">
        {navigationGirl?.contact ? (
          <ContactInfo
            contact={navigationGirl.contact.id}
            activeRecipient={navigationGirl.contact}
            closeModal={() => dispatch(closeModal())}
            saveMapState={saveMapState}
          />
        ) : <Spinner spinnerSize={44} />}
      </div>
    </>
  )
}

export default React.memo(GirlPreviewOnMap);
