import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { v4 } from 'uuid';
import axios from 'axios';

import API from 'api/api';
import { debounce } from "utils";
import { GIRLS_LIMIT } from 'redux/ducks/contacts';
import { 
  DIVA_GIRLS_LINK,
  DIVA_GIRLS_IMG_HOST_PREVIEW, 
  DIVA_GIRLS_ADMIN_AVAILABILITY, 
  DIVA_GIRLS_ADMIN_PROFILE_DETAILS, 
} from 'redux/ducks/divaGirls';

import GirlsList from 'components/UI/GirlsList/GirlsList';


const AdrBookProfilesPopupList = React.forwardRef(({ 
  setPhoto,
  isShowPopup, 
  setIsShowPopup,
  setIsShowSpinner,
  getTooltipProps,
}, ref) => {
  const [popupState, setPopupState] = useState({
    part: 1,
    items: [],
    hasMore: false,
    isStartResponse: true,
    isPendingPopupItems: false,
  });

  const form = useForm();
  const { values } = useFormState();

  const query = useMemo(() => values.fnParts?.name, [values.fnParts?.name]);

  const PAGE_SIZE = 25;
  const { part, items, hasMore, isStartResponse, isPendingPopupItems } = popupState;

  useEffect(() => {
    if(query?.length > 1 && isStartResponse) {
      const params = {
        part: 1,
        search: query,
        limit: GIRLS_LIMIT,
      };

      handleSearchDebounce(params);
    }
  }, [query]);

  useEffect(() => {
    if(!isShowPopup) {
      setPopupState(prev => ({ ...prev, isStartResponse: true }))
    }
  }, [isShowPopup]);

  useEffect(() => {
    !isShowPopup && setPopupState(prev => ({ ...prev, part: 1 }))
  }, [isShowPopup]);

  const fetchData = useCallback(async (params) => {
    const { data } = await API.getActiveDivaGirls(params);
    return ({
      newItems: data.result,
      newHasMore: data.result?.length === PAGE_SIZE
    });
  }, []);

  const fetchFirstPage = (params) => {
    setIsShowSpinner(true);

    fetchData(params)
      .then(({ newItems = [], newHasMore }) => {
        (!isShowPopup || newItems?.length) && setIsShowPopup(true);

        setPopupState(prev => ({ 
          ...prev, 
          part: prev.part + 1,
          items: newItems,
          hasMore: newHasMore,
          isPendingPopupItems: false,
        }));
      })
      .finally(() => setIsShowSpinner(false));
  };

  const handleSearchDebounce = useCallback(debounce(fetchFirstPage, 700), []);

  const fetchNextPage = () => {
    fetchData({ limit: GIRLS_LIMIT, part, search: query })
      .then(({ newItems = [], newHasMore }) => {
        setPopupState(prev => ({ 
          ...prev, 
          part: prev.part + 1,
          hasMore: newHasMore,
          items: [...prev.items, ...newItems],
        }));
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setPopupState(prev => ({ 
            ...prev, 
            hasMore: false,
          }))
        }
      });
  };

  const handleChoose = useCallback((selectedProfile) => {
    // getting a diva girl's profile to form a full name in the contact list

    const { 
      id,
      link,
      name,
      place,
      agents,
      avatar,
      prices,
      location_main,
      nationality,
    } = selectedProfile;

    const url = DIVA_GIRLS_IMG_HOST_PREVIEW + avatar;
    const oneHourPrice = prices.find(item => item.name === '1 hour');
    const thirtyMinutesPrice = prices.find(item => item.name === '30 minutes')?.incall || '-';
    const totalPriceData = `${thirtyMinutesPrice}/${oneHourPrice?.incall || '-'}/${oneHourPrice?.outcall || '-'}`;

    // const fn = `${name} ${totalPriceData} ${location_main} ${agents.join(' ')} ID ${id}`;
    // const fn = `${name} ${totalPriceData} ${location_main} ${nationality} ${agents.join(' ')} ID ${id}`;

    const generatedUrls = [
      {
        title: "Profile",
        url: DIVA_GIRLS_LINK + link,
      },
      {
        title: "admin",
        url: DIVA_GIRLS_ADMIN_PROFILE_DETAILS + id,
      },
      {
        title: "availability",
        url: DIVA_GIRLS_ADMIN_AVAILABILITY + id,
      }
    ];

    setPhoto({ url, file: null });

    // form.change('fn', fn);
    form.change('fnParts.name', name);
    form.change('fnParts.custom_id', id);
    form.change('fnParts.prices', totalPriceData);
    form.change('fnParts.location', location_main);
    form.change('fnParts.nationality', nationality);
    form.change('urls', generatedUrls);
    form.change('photo', { src: url });
    form.change('addresses', [{ address: place, uuid: v4() }]);

    setPopupState(prev => ({ ...prev, isStartResponse: false }));
    setIsShowPopup(false);
  }, []);

  if(!items.length || !query) {
    return null;
  }

  const mergedItems = items?.map(item => ({
    ...item,
    photo: { min: DIVA_GIRLS_IMG_HOST_PREVIEW + item.avatar },
    isProfile: true
  }));

  return (
    isShowPopup && (
      <div
        className='adr-book-popup-wrapper'
        ref={ref}
        {...getTooltipProps()}
      >
        <GirlsList
          title="Import escort record from Diva admin"
          items={mergedItems}
          query={query}
          hasMore={hasMore}
          onItemClick={handleChoose}
          fetchNextPage={fetchNextPage}
          isLoading={isPendingPopupItems}
        />
      </div>
    )
  )
});

export default React.memo(AdrBookProfilesPopupList);
