import { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { classModifier, getContactAvatar } from 'utils';
import { MAP_GIRL_FILTERS, MAP_PRIMARY_MODES, MAP_SECONDARY_MODES, addSecondaryModeId, removeSecondaryModeId, updateSecondaryModeId } from 'redux/ducks/map';
import { selectMapGirlIdBySecondaryMode, selectMapGirlIdsByFilters, selectMapGirlIdsBySecondaryMode, selectMostImportantSecondaryMode } from 'redux/selectors/selectors';
import { GirlsMapModalContext } from '../../GirlsMapModal';

import Marker from '../../../../../../components/UI/Marker/Marker';
import GirlsMarkerTooltip from './GirlsMarkerTooltip';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import ICONS from 'assets/icons';


const GirlsMarker = ({ cluster, supercluster, ...restProps }) => {
  const { primaryMode, secondaryMode, clientData, girlsData } = useSelector(state => state.map);
  const selectedGirlIds = useSelector(state => selectMapGirlIdsBySecondaryMode(state, MAP_SECONDARY_MODES.SELECT_ESCORTS));
  const targetGirl = useSelector(state => selectMapGirlIdsBySecondaryMode(state, MAP_SECONDARY_MODES.TARGET_ESCORT));
  const hostForSimilarEscortId = useSelector(
    state => selectMapGirlIdBySecondaryMode(state, MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT)
  );
  const bufferedGirlIds = useSelector(state => selectMapGirlIdsByFilters(state, MAP_GIRL_FILTERS.BUFFERED_ESCORTS));
  const mostImportantSecondaryMode = useSelector(selectMostImportantSecondaryMode);
  
  const { saveMapState } = useContext(GirlsMapModalContext);
  
  const [shouldCloseTooltip, setShouldCloseTooltip] = useState(false);
  
  const dispatch = useDispatch();
  
  const { available_status, profile_id, caller_id } = cluster.properties;
  const isSelectedSecondaryMode = secondaryMode[MAP_SECONDARY_MODES.SELECT_ESCORTS];
  const isTargetMode = primaryMode === MAP_PRIMARY_MODES.TARGET_ESCORT;
  const isProfileSelected = mostImportantSecondaryMode === MAP_SECONDARY_MODES.SELECT_ESCORTS && selectedGirlIds.includes(profile_id)
  const isProfileTargered = mostImportantSecondaryMode === MAP_SECONDARY_MODES.TARGET_ESCORT && targetGirl === profile_id;
  const isProfileBuffered = bufferedGirlIds.includes(profile_id);
  
  const classes = classModifier('girl-marker', available_status && 'available');
  const girlData = girlsData[profile_id].girl;

  const handleClick = () => {
    if (isTargetMode) {
      dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.TARGET_ESCORT, { profile_id, caller_id }));
      dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT, null));
    }

    if (!isSelectedSecondaryMode) return;

    if (isProfileSelected) {
      dispatch(removeSecondaryModeId(MAP_SECONDARY_MODES.SELECT_ESCORTS, profile_id));
    } else {
      dispatch(addSecondaryModeId(MAP_SECONDARY_MODES.SELECT_ESCORTS, { profile_id, caller_id }));
    }
  }

  const girlMarkerTooltip = useMemo(() => (
    <GirlsMarkerTooltip
      girlInformation={cluster.properties}
      setShouldCloseTooltip={setShouldCloseTooltip}
      saveMapState={saveMapState}
    />
  ), [clientData]);

  const bestMatchPercentageLabel = useMemo(() => {
    const classes = classModifier(
      "girls-map__bestmatch-label",
      girlsData.bestmatch < 50 ? 'low' : girlData.bestmatch > 80 ? 'high' : 'medium'
    )

    if (!girlData.bestmatch) return null;

    return (
      <mark className={classes}>
        {`${girlData.bestmatch}%`}
      </mark>
    )
  }, [girlData])

  return (
    <Marker
      className={classes}
      tooltip={girlMarkerTooltip}
      extraTooltipCondition={!shouldCloseTooltip}
      selected={isProfileSelected}
      onClick={handleClick}
      isLarge={isProfileTargered}
      bottomIcon={isProfileBuffered && ICONS.buffer}
      floatingLabel={isTargetMode && hostForSimilarEscortId && bestMatchPercentageLabel}
      {...restProps}
    >
      <LazyLoadImage
        alt="photo"
        src={getContactAvatar(cluster.properties)}
        className="girls-map__marker-img"
      />
    </Marker>
  );
};

export default GirlsMarker;
