import React, { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';

import { selectAddrBookActiveContact } from 'redux/selectors/selectors';

import ContactLogsTab from './components/ContactLogsTab/ContactLogsTab';
import ContactAddressTab from './components/ContactAddressTab/ContactAddressTab';
import ContactRequestsTab from './components/ContactRequestsTab/ContactRequestsTab';
import FeedbackList from 'components/FeedbackList/FeedbackList';
import FeedbackAddBtn from 'components/FeedbackAddBtn/FeedbackAddBtn';
import ContactNotesTab from './components/ContactNotesTab/ContactNotesTab';
import { CONTACT_TYPES } from 'config/constants';
import ContactLinksTab from './components/ContactLinksTab/ContactLinksTab';
import ContactBookings from 'containers/Dialogs/components/ContactCard/components/ContactBookings/ContactBookings';

const AdrBookContactFormTabs = (props) => {
  const {
    values,
    saveContact,
    editedContact = {},
    serverError,
    setServerError,
    feedbackList,
    setFeedbackList,
    labelsOptions,
    isUrlsLabelEdited,
    isNewContactCreation,
    activeContact,         // contains actual contact (unlike editedContact)
    tabIndex,
    setTabIndex,
  } = props;

  const activeContactInForm = isNewContactCreation ? null : activeContact;

  useEffect(() => {
    setTabIndex(0);
  }, [editedContact.id, isNewContactCreation]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        {/* {editedContact.type === CONTACT_TYPES.GIRL &&  */}
          <Tab>Links</Tab>
        {/* // } */}
        <Tab>Notes</Tab>
        <Tab>Address</Tab>
        <Tab>Log</Tab>
        {[CONTACT_TYPES.CLIENT, CONTACT_TYPES.GIRL].includes(Number(values?.type)) &&
          <Tab>Bookings</Tab>
        }
        <Tab>Requests</Tab>
        <Tab>Feedbacks</Tab>
      </TabList>

      {/* {editedContact.type === CONTACT_TYPES.GIRL &&  */}
        <TabPanel>
          <ContactLinksTab 
            saveContact={(fieldName, value) => {
              (!fieldName && !value) ? saveContact(values) : saveContact({ ...values, [fieldName]: value })}
            }
            callerId={editedContact.id}
            labelsOptions={labelsOptions}
            isUrlsLabelEdited={isUrlsLabelEdited}
          />
        </TabPanel>
      {/* } */}

      <TabPanel>
        <ContactNotesTab 
          contactNote={values.note} 
          setEmptyNoteValue={() => activeContact.note && saveContact({ ...values, note: null })}
        />
      </TabPanel>

      <TabPanel>
        <ContactAddressTab 
          values={values}
          serverError={serverError}
          saveContact={saveContact}
          editedContact={editedContact} 
          setServerError={setServerError}
        />
      </TabPanel>

      <TabPanel>
        <ContactLogsTab 
          id={values.id}
          name={values.fn}
        />
      </TabPanel>

      {[CONTACT_TYPES.CLIENT, CONTACT_TYPES.GIRL].includes(Number(values?.type)) &&
        <TabPanel>
          <div className="adr-book-contact-form__tab adr-book-contact-form__tab--bookings">
            {(editedContact.type !== CONTACT_TYPES.GIRL
            || editedContact.type === CONTACT_TYPES.GIRL && editedContact.diva_default_id) && (
              <ContactBookings activeRecipient={editedContact} />
            )}
          </div>
        </TabPanel>
      }

      <TabPanel>
        <ContactRequestsTab
          id={editedContact.id}
          isSortByNewest
        />
      </TabPanel>

      <TabPanel>
        <div className='adr-book-contact-form__feedbacks-tab'>
          <div>
            <FeedbackAddBtn
              activeContact={activeContactInForm}
              setFeedbackList={setFeedbackList}
            />
          </div>

          <FeedbackList
            contactId={isNewContactCreation ? null : editedContact.id}
            feedbackList={feedbackList}
            setFeedbackList={setFeedbackList}
            activeContact={activeContactInForm}
          />
        </div>
      </TabPanel>
    </Tabs>
  )
}

const mSTP = state => ({
  activeContact: selectAddrBookActiveContact(state),
});

export default connect(mSTP)(AdrBookContactFormTabs);
