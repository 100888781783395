import React from "react";
import { useSelector } from "react-redux";

const AttachmentEmail = ({ email }) => {
  const isMailPageOpen = useSelector((state) => state.user.isMailPageOpen);

  const onClick = () => {
    if (!isMailPageOpen) {
      return window.open(`/mail/inbox/email/${email?.conversationId}`, "_blank");
    }
    const channel = new BroadcastChannel('tab_channel');
    channel.postMessage({ name: 'openMail', conversationId: email?.conversationId });
  };

  return (
    <div className="attachment-email" onClick={onClick}>
      <p className="attachment-email__title">{email.subject}</p>
      <p className="attachment-email__body">{email.body}</p>
    </div>
  );
};

export default AttachmentEmail;
