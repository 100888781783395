import { useDispatch, useSelector } from 'react-redux';
import { MAP_SECONDARY_MODES, updateSecondaryModeId } from 'redux/ducks/map';
import { selectMapGirlIdBySecondaryMode } from 'redux/selectors/selectors';
import { classModifier } from 'utils';


const SimilarEscortsButton = () => {
  const targetGirlId = useSelector(state => selectMapGirlIdBySecondaryMode(state, MAP_SECONDARY_MODES.TARGET_ESCORT));
  const hostForSimilarEscortId = useSelector(state => selectMapGirlIdBySecondaryMode(state, MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT));

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT, targetGirlId));
  }

  return (
    <button
      className={classModifier("girls-map__similar-button", hostForSimilarEscortId && 'active')}
      onClick={handleClick}
    >
      Similar escorts
    </button>
  )
}

export default SimilarEscortsButton;
