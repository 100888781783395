/* This is a hook for loading filtered bookings */

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  createInitialState,
  reducer,
  updateBookings,
  setDate,
  setDayBefore,
  setDayAfter,
  changeBooleanFilter,
  changeDuoFilter,
  changeOutcallFilter,
  changeStatusFilter,
  changeFilterId,
  resetDate,
  changeSorting,
  setPending,
  setExtraState,
  setQuery,
  resetQuery,
  resetDayFilter,
  resetAllDayFilters,
  changeCurrentGirlFilter,
  resetFilter,
  changeBookingState,
  deleteSavedFilter,
  deleteInitialBookingState,
  changeConfigKey
} from '../store/reducer'
import { selectBookingFilters, selectBookingObject, selectBookingsActualCount, selectBookingsTotalCount, selectBoundaryDate, selectExtraItemsState, selectFilteredBookings, selectIsDateLoaded, selectIsFiltersUpdated, selectNumberOfBookings, selectOverallPending, selectPending, selectSplitedDate, selectStatusCounters } from '../selectors/selectors';
import API from 'api/api';
import { useDidMount, useDidUpdate, usePrevious } from 'hooks';
import {
  BOOKING_FILTERS,
  BOOKING_FILTERS_NAMES,
  BOOKING_SELECT_FILTERS,
  BOOKING_SELECT_FILTERS_NAMES,
  BOOKING_STATUS_FILTERS,
  BOOKING_STATUS_FILTERS_NAMES,
  NO_BOOKINGS_LABELS,
} from '../config/constants';
import { selectUserTimezone } from 'redux/selectors/selectors';
import convertToOptimizeBooking from 'utils/convertToOptimizeBooking';
import getConfigForOptimizedBookingsApi from 'utils/getConfigForOptimizedBookingsApi';
import { useEnchancedReducer } from 'hooks/useEnchancedReducer';
import getDateConfig from '../utils/getDateConfig';
import { updateBookings as updateBookingsInRedux } from 'redux/ducks/bookings';
import getActualDate from '../utils/getActualDate';
import convertDateToBookingsKey from '../utils/convertDateToBookingsKey';
import getBookingAfterTime from '../utils/getBookingAfterTime';
import useSocket from 'hooks/useSocket';
import axios from 'axios';
import useChangeEffect from 'hooks/useChangeEffect';
import getHashOfFilters from '../utils/getHashOfFilters';
import getDiffKeysFromObjects from 'utils/getDiffKeysFromObjects';


const SELECT_FILTERS_WITH_FILTER_PROPS = [
  BOOKING_SELECT_FILTERS_NAMES.client,
  BOOKING_SELECT_FILTERS_NAMES.girl
]


export const useFilteredBookings = (config, configKey) => {
  const userTimezone = useSelector(selectUserTimezone);
  const totalBookings = useSelector((state) => state.bookings.entities); // TODO: create local copy of item state for socket processing in home project

  const reduxDispatch = useDispatch();
  const bookingsRef = useRef({});
  const filtersKeysQueue = useRef([]);

  const getConfig = (configKey) => config instanceof Function ? config(configKey) : config;

  const {
    extraConfig,
    extraFiltersConfig,
    boundaryDays,
    separatedBookingsByDay,
    separatedFiltersByDay,
    isAutoScrollToActualTime,
    duringAllTime,
    memoizedFilters = 2,
    limit = extraConfig?.limit || 30,
  } = getConfig(configKey);
  const filtersByDayCount = separatedFiltersByDay ? boundaryDays : 1;

  const initialstate = createInitialState(duringAllTime, userTimezone);
  const [state, dispatch] = useEnchancedReducer(reducer, initialstate);

  const { filters: { searchQuery, general: { date: actualDate, currentDate } } } = state;
  const { girlId } = extraFiltersConfig || {};

  let boundaryDate, splitedDate;
  if (!duringAllTime) {
    boundaryDate = selectBoundaryDate(state, boundaryDays);
    splitedDate = selectSplitedDate(state, separatedBookingsByDay, boundaryDays);
  }

  const previousState = usePrevious(state);
  const previousExtraFiltersConfig = usePrevious(extraFiltersConfig);

  const isCurrentDate
    = (date) => convertDateToBookingsKey(currentDate) === convertDateToBookingsKey(date);


  /* Bind all filter props to function for passing it */

  const getFilterProps = (date) => {
    const filters = selectBookingFilters(state, date);
    const apiConfig = getConfigForOptimizedBookingsApi(filters);
    const dateConfig = getDateConfig(date);

    return {
      ...apiConfig,
      ...dateConfig,
      ...extraConfig,
      // limit: isCurrentDate(date) ? '' : limit,
      limit: Array.isArray(date) ? limit : '',
    };
  }

  /* After updated day side effect logic */

  const afterUpdatedDayCallbacks = {};

  const saveCallbackByDate = (callback, date) => {
    const actualDate = date || boundaryDate;

    afterUpdatedDayCallbacks[actualDate] = callback;
  }

  const executeCallbackAfterUpdatedDay = (bookings, date) => {
    const bookingIds = bookings.map((booking) => booking.id);
    const filters = getFilterProps(date);
    const callback = afterUpdatedDayCallbacks[date];

    callback && callback(bookingIds, filters);
  }

  /* Update bookings either by days or by specific date range or without date */

  const loadFilteredBoookingsByDate = (offset, date) => {
    if (!offset) dispatch(setPending(date, true));

    const cancelToken = bookingsRef.current[date]?.cancelTokenSource?.token;

    return API.getOptimizedBookingsByDate({ ...getFilterProps(date), offset, cancelToken })
      .then(({ data }) => {
        const { bookings } = data;

        const optBookingsList = bookings.map((booking) => convertToOptimizeBooking(booking, true)) || [];

        reduxDispatch(updateBookingsInRedux(optBookingsList));
        dispatch(updateBookings(data, date, Boolean(offset)));
        if (!offset) dispatch(setPending(date, false));

        return { bookings };
      })
      .catch(() => {
        const filtersKey = getHashOfFilters(selectBookingFilters(state, date));
        dispatch(deleteInitialBookingState(date, filtersKey));
        // bookingsRef.current[date].cancelTokenSource = axios.CancelToken.source();
      });
  }

  async function* loadFilteredBookings({ pickedDate = splitedDate, offset } = {}) {
    const formatedPickedDate = Array.isArray(pickedDate) ? pickedDate : [pickedDate];
    const promiseIndexes = [];

    const bookingPromises = formatedPickedDate.map(
      (date, index) =>
        loadFilteredBoookingsByDate(offset, date)
          .then((data) => ({ index, date, entities: data?.bookings }))
          .catch(() => ({ index }))
    );

    while (promiseIndexes.length !== bookingPromises.length) {
      const filteredBookingPromises = bookingPromises.filter((_, index) => !promiseIndexes.includes(index))
      const result = await Promise.any(filteredBookingPromises);

      promiseIndexes.push(result.index);

      yield result;
    }
  }

  /* If you want to use memoization in your bookings check it out */

  const createExtraBookingsState = (date) => (bookingId, value, name) => {
    const extraBookingState = selectExtraItemsState(state, date, bookingId, name) || value;
    const setExtraBookingState = (newValue) => {
      if (newValue === extraBookingState) return;

      dispatch(setExtraState(date, bookingId, newValue, name))
    };

    return [extraBookingState, setExtraBookingState];
  }

  const getExtraBookingsState = (date, bookingId, name) => selectExtraItemsState(state, date, bookingId, name);

  /* Socket listener */

  useSocket((event) => {
    const { data } = JSON.parse(event.data);
    let newBooking;
    let oldBooking;

    switch (data.type) {
      case 'update_session_booking':
        newBooking = data.booking;
        oldBooking = data.oldBooking;
        break;
      case 'create_session_booking':
        newBooking = data.booking;
        break;
      case 'delete_session_booking':
        oldBooking = data.booking;
        break;
      default:
        return;
    }

    dispatch(changeBookingState(oldBooking, newBooking));
  })

  /* Functions for autoscrolling to the booking that holds date corresponds to current one */

  const isDayAllowedToAutoScroll = () => {
    const isFirstDay = !splitedDate || splitedDate[0] === currentDate;
    const isFirstBookingsLoad = !state.filters.updatedDate;

    return isFirstDay && isFirstBookingsLoad && isAutoScrollToActualTime;
  }

  const handleScrollToActualTime = (bookings, scrollTime) => {
    if (!isDayAllowedToAutoScroll()) return;
    if (!bookings.length) return;

    const scrolledBooking = getBookingAfterTime(bookings, userTimezone, scrollTime);
    const bookingDomElement = bookingsRef.current[currentDate].nodes[scrolledBooking.id];

    bookingDomElement?.scrollIntoView();
  };

  /* Function for saving bookings with specific dates and specific filters for returning without sending request */

  const reduceBookingState = (updatedDate) => {
    if (!splitedDate) return;

    const updatedDateFiltersKey = getHashOfFilters(selectBookingFilters(state, updatedDate));
    const restFiltersKeys = splitedDate.map((date) => getHashOfFilters(selectBookingFilters(state, date)));

    if (!filtersKeysQueue.current.includes(updatedDateFiltersKey)) {
      if (filtersKeysQueue.current.length === memoizedFilters) {
        const notActiveFiltersKey = filtersKeysQueue.current.find((key) => !restFiltersKeys.includes(key));
        const filtersKeyForDeleting = notActiveFiltersKey || filtersKeysQueue.current.at(-1);
        filtersKeysQueue.current = filtersKeysQueue.current.filter((key) => key !== filtersKeyForDeleting);

        dispatch(deleteSavedFilter(filtersKeyForDeleting))
      }

      filtersKeysQueue.current.unshift(updatedDateFiltersKey);
    }
  }

  /* Predefined callbacks for executing after updating day */

  const executePredefinedCallbacksAfterUpdatedDay = ({ entities, index, date }) => {
    executeCallbackAfterUpdatedDay(entities, date);
    index === 0 && handleScrollToActualTime(entities);
  }

  /* When day or filter is changed we should cancel unloaded request for day or filter that isn't showed */
  
  const cancelRequestsForNotLongerDisplayedFilters = () => {
    let notLongerDisplayedDates = [];
    const previousConfigKey = previousState.settings.configKey;

    if (!splitedDate) return;
    
    const { separatedBookingsByDay, boundaryDays } = getConfig(previousConfigKey || configKey);
    const previousSplitedDate = selectSplitedDate(previousState, separatedBookingsByDay, boundaryDays);
    
    previousSplitedDate?.forEach((date) => {
      const getFiltersKey = (state, date) => getHashOfFilters({
        date,
        ...selectBookingFilters(state, date)
      });
      const filtersKeyToPreviousDate = getFiltersKey(previousState, date);
      const filtersKeysToAllSplitedDates = splitedDate.map((date) => getFiltersKey(state, date));

      if (!filtersKeysToAllSplitedDates.includes(filtersKeyToPreviousDate)) {
        notLongerDisplayedDates.push(date);
      }
    })

    notLongerDisplayedDates.forEach((date) => {
      bookingsRef.current[date]?.cancelTokenSource?.cancel();
      bookingsRef.current[date].nodes = {};
    });
  }

  /* Function for bookings ref processing */
  
  const updateBookingsRefObject = (unloadedDates) => {
    const reduceObject = (acc, date) => {
      const cancelTokenSource = axios.CancelToken.source();
      acc[date] = { nodes: {}, cancelTokenSource };

      return acc;
    }

    bookingsRef.current = unloadedDates
      ? unloadedDates.reduce(reduceObject, bookingsRef.current)
      : reduceObject(bookingsRef.current, unloadedDates);
  }

  /* Side effect for resetting filters when config is changed */

  useEffect(() => {
    if (getConfig(configKey)) {
      dispatch(resetAllDayFilters(filtersByDayCount, splitedDate));
      dispatch(changeConfigKey(configKey));
    }
  }, [configKey])

  /* Side effect for sending request if filters were changed */
  
  useDidUpdate(async () => {
    const { updatedDate } = state.filters;
    const unloadedDates = splitedDate?.filter((date) => !selectIsDateLoaded(state, date));

    if (!unloadedDates?.length === 0) return;

    cancelRequestsForNotLongerDisplayedFilters();
    updateBookingsRefObject(unloadedDates);
    reduceBookingState(updatedDate);

    const renderedBookingsByDayGenerator = loadFilteredBookings({ pickedDate: unloadedDates });

    for await (const bookings of renderedBookingsByDayGenerator) {
      if (!bookings.entities) continue;

      executePredefinedCallbacksAfterUpdatedDay(bookings);
    }
  }, [state.filters, state.settings.configKey])

  /* Side effect for resetting filters when extra filter config is changed */

  useEffect(() => {
    const changedFilterNames = getDiffKeysFromObjects(previousExtraFiltersConfig, extraFiltersConfig);
    const filters = selectBookingFilters(state, currentDate);

    changedFilterNames.forEach((name) => {
      if (filters[name]) {
        dispatch(resetFilter(currentDate, name))
      }
    })
  }, [extraFiltersConfig, currentDate])

  /* PROPS GETTERS fucntions for conducting props to components */

  const getters = new Proxy({}, {
    get(target, method) {
      return (props) => {
        const date = props?.date || boundaryDate;
        const result = target[method]({ ...props, date })

        const complexGetterNames = ['getFilterProps', 'getStatusFilterProps', 'getSelectProps'];
        const isNotReturnedGetterPropsObject = complexGetterNames.includes(method) ? props?.name : true;

        if (isNotReturnedGetterPropsObject) {
          return result;
        } else {
          return new Proxy(result, this);
        }
      }
    }
  })

  getters.getDateChangerProps = () => ({
    changeDateToPrev: () => dispatch(setDayBefore()),
    changeDateToNext: () => dispatch(setDayAfter()),
  })

  getters.getDateTimeChangerProps = () => ({
    date: boundaryDate,
    range: boundaryDays,
    setDate: (date) => dispatch(setDate(date)),
    ...getters.getDateChangerProps(),
  })

  getters.getSearchBookingsProps = () => ({
    searchQuery,
    startSearch: (query) => dispatch(setQuery(query)),
    stopSearch: () => dispatch(resetQuery()),
  })

  const getSimpleFilterProps = ({ name, date, ...restProps }) => ({
    onClick: () => dispatch(changeBooleanFilter(date, name)),
    active: selectBookingFilters(state, date)[name],
    statusCount: selectStatusCounters(state, date, name),
    ...(BOOKING_FILTERS[name] || {}),
    ...restProps
  })

  const getDuoFilterProps = ({ name, date, ...restProps }) => ({
    onClick: () => dispatch(changeDuoFilter(date)),
    active: selectBookingFilters(state, date).meeting_type === 'duo',
    ...BOOKING_FILTERS[name],
    ...restProps,
  })

  const getOutcallFilterProps = ({ name, date, ...restProps }) => ({
    onClick: () => dispatch(changeOutcallFilter(date)),
    active: selectBookingFilters(state, date).type === 'outcall',
    ...BOOKING_FILTERS[name],
    ...restProps
  })

  const getCurrentGirlFilterProps = ({ name, date, ...restProps }) => ({
    onClick: () => dispatch(changeCurrentGirlFilter(date, girlId)),
    active: selectBookingFilters(state, date).girlId === girlId,
    ...BOOKING_FILTERS[name],
    ...restProps,
  })

  getters.getFilterProps = ({ name, date, ...restProps }) => {
    const getterProps = ({ name, restInnerProps }) => {
      const restAllProps = restInnerProps || restProps;
      const innerProps = { name, date, ...restAllProps };

      return {
        [BOOKING_FILTERS_NAMES.prebooking]: getSimpleFilterProps(innerProps),
        [BOOKING_FILTERS_NAMES.agent]: getSimpleFilterProps(innerProps),
        [BOOKING_FILTERS_NAMES.finished]: getSimpleFilterProps(innerProps),
        [BOOKING_FILTERS_NAMES.duo]: getDuoFilterProps(innerProps),
        [BOOKING_FILTERS_NAMES.outcall]: getOutcallFilterProps(innerProps),
        [BOOKING_FILTERS_NAMES.currentGirl]: getCurrentGirlFilterProps(innerProps),
        [BOOKING_FILTERS_NAMES.notes]: getSimpleFilterProps(innerProps),
        [BOOKING_FILTERS_NAMES.requests]: getSimpleFilterProps(innerProps),
      }[name];
    }

    if (name) {
      return getterProps({ name, restInnerProps: restProps });
    } else {
      return { getterProps };
    }
  }

  getters.getStatusFilterProps = ({ name, date, ...restProps }) => {
    const getterProps = ({ name, restInnerProps }) => {
      const { confirmationStatus } = selectBookingFilters(state, date);

      const { label, color } = BOOKING_STATUS_FILTERS[name];
      const isAllFilter = name === BOOKING_STATUS_FILTERS_NAMES.all;
      const active = isAllFilter ? !confirmationStatus : confirmationStatus === label;
      const statusCount = isAllFilter
        ? selectBookingObject(state, date).entity?.bookingsTotal
        : selectStatusCounters(state, date, label);
      const onClick = () => dispatch(changeStatusFilter(date, isAllFilter ? null : label));

      return {
        label,
        color,
        active,
        statusCount,
        onClick,
        ...(restInnerProps || restProps)
      }
    }

    if (name) {
      return getterProps({ name, restInnerProps: restProps });
    } else {
      return { getterProps };
    }
  }

  getters.getSelectProps = ({ date, ...restProps }) => {
    const getRequest = (name) => (requests) => {
      if (SELECT_FILTERS_WITH_FILTER_PROPS.includes(name)) {
        return (props) => requests[name]({ ...getFilterProps(splitedDate[0]), ...props });
      } else {
        return requests[name];
      }
    }

    const getterProps = ({ name, restInnerProps }) => {
      const props = BOOKING_SELECT_FILTERS[name];
      const filterValue = selectBookingFilters(state, date)[props.filterName];
      const newRequest = getRequest(name);
      const updateFilters = (innerProps, pathToValue) => {
        dispatch(changeFilterId(date, props.filterName, innerProps?.value || innerProps?.[pathToValue]))
      };
      const isFiltersUpdated = selectIsFiltersUpdated(state);

      return {
        ...props,
        filterValue,
        isFiltersUpdated,
        updateFilters,
        newRequest,
        ...(restInnerProps || restProps)
      };
    }

    return { getterProps };
  };

  getters.getTimeFiltersProps = ({ date }) => {
    const bookings = selectFilteredBookings(state, date, totalBookings);

    return {
      onClick: (scrollTime) => handleScrollToActualTime(bookings, scrollTime)
    }
  };

  getters.getTodayButtonProps = () => ({ onClick: () => dispatch(resetDate()) });

  getters.getFiltersContainerProps = ({ date, ...restProps }) => ({
    onReset: () => dispatch(resetDayFilter(date)),
    bookingsCount: selectNumberOfBookings(state, date),
    isUpdated: selectIsFiltersUpdated(state, date, { withoutGeneralFilters: true }),
    ...restProps,
  })

  getters.getSortOptionsProps = ({ date }) => {
    const filters = selectBookingFilters(state, date);

    return {
      onChangeSort: (sortOption) => dispatch(changeSorting(date, sortOption)),
      activeSortOption: filters.sortOption,
      isAsk: filters.sortOptionType === 'asc',
    }
  };

  getters.getBookingsCount = ({ date }) => {
    const totalCount = selectBookingsTotalCount(state, date);
    const actualCount = selectBookingsActualCount(state, date);
    const isFiltersUpdated = selectIsFiltersUpdated(state, date);

    return {
      currentCount: isFiltersUpdated ? actualCount : totalCount,
      totalCount,
    }
  }

  getters.getBookingListProps = ({
    date,
    groupByDay,
    absentLabel = NO_BOOKINGS_LABELS.GENERAL,
    extraPending = false,
  }) => {
    const bookings = selectFilteredBookings(state, date, totalBookings, groupByDay);
    const realBookingsLength = selectFilteredBookings(state, date, totalBookings).length;
    const getAbsentLabel = {
      [NO_BOOKINGS_LABELS.GENERAL]: () => "(no bookings)",
      // [NO_BOOKINGS_LABELS.CHOOSEN_FILTERS]: () => getFilteredMessage(date),
      [NO_BOOKINGS_LABELS.NO_LABEL]: () => ""
    }
    const pending = selectPending(state, date);
    const loadMore = (offset) => {
      Array.isArray(date) && loadFilteredBoookingsByDate(offset, date);
    }

    return {
      limit,
      list: bookings,
      loadMore,
      listLoadPending: extraPending || pending,
      absentLabel: getAbsentLabel[absentLabel](),
      ...(groupByDay ? { realListLength: realBookingsLength } : {})
    }
  }

  getters.getBookingProps = ({ date }) => {
    return {
      getCallbackRef: (bookingId) => (node) => {
        bookingsRef.current[date].nodes[bookingId] = node;
      },
      useExtraBookingState: createExtraBookingsState(date),
    }
  }

  return {
    getters,
    date: splitedDate,
    actualDate: getActualDate(actualDate, userTimezone),
    isCurrentDate: isCurrentDate(actualDate),
    getFilterProps,
    useDayUpdated: saveCallbackByDate,
    getExtraBookingsState,
  }
}
