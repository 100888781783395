import { CONTACT_TYPES } from 'config/constants';
import { getContactsById } from './contacts';
import { getActiveDivaGirls } from './divaGirls';

export const DEFAULT_ZOOM = 14;

export const MAP_PRIMARY_MODES = {
  ALL_ESCORTS: 'all-escorts',
  CHAT_ESCORTS: 'chat-escorts',
  SESSION_ESCORT: 'session-escort',
  NAVIGATION: 'navigation',
  TARGET_ESCORT: 'target-escort',
}

export const MAP_SECONDARY_MODES = {
  HOST_FOR_SIMILAR_ESCORT: 'host-for-similar-escort',
  TARGET_ESCORT: 'target-escort',
  SELECT_ESCORTS: 'select-escorts',
  RADIUS_ESCORTS: 'radius-escorts',
}

export const MAP_GIRL_FILTERS = {
  CHAT_ESCORTS: 'chat-escorts',
  FINDED_ESCORT: 'finded-escort',
  AVAILABLE_ESCORTS: 'available-escorts',
  BUFFERED_ESCORTS: 'buffered-escorts',
}

export const CLIENT_INITIAL_OBJECT = {
  longitude: null,
  latitude: null,
  radius: { value: 0 },
  location: '',
};
export const CENTER_COORDINATES = { lat: 51.50208118987632, lng: -0.16862733974063682 };

const UPDATE_ZOOM = 'UPDATE_ZOOM';
const UPDATE_CENTER = 'UPDATE_CENTER';
const UPDATE_GIRLS_COORDINATES_DATA = 'UPDATE_GIRLS_COORDINATES_DATA';
const UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION = 'UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION';
const UPDATE_CLIENT_DATA = 'UPDATE_CLIENT_DATA';
const UPDATE_PRIMARY_MODE = 'UPDATE_PRIMARY_MODE';
const UPDATE_SECONDARY_MODE = 'UPDATE_SECONDARY_MODE';
const RESET_SECONDARY_MODE = 'RESET_SECONDARY_MODE';
const UPDATE_SECONDARY_MODE_IDS = 'UPDATE_SECONDARY_MODE_IDS';
const ADD_SECONDARY_MODE_ID = 'ADD_SECONDARY_MODE_ID';
const REMOVE_SECONDARY_MODE_ID = 'REMOVE_SECONDARY_MODE_ID';
const UPDATE_PRIMARY_MODE_PROPS = 'UPDATE_PRIMARY_MODE_PROPS';
const UPDATE_MAP_LOCATION = 'UPDATE_MAP_LOCATION';
const UPDATE_CLIENT_LOCATION = 'UPDATE_CLIENT_LOCATION';
const UPDATE_FILTER = 'UPDATE_FILTER';
const UPDATE_FILTER_GIRL_IDS = 'UPDATE_FILTER_GIRL_IDS;'
const UPDATE_PRIMARY_MODE_GIRL_IDS = 'UPDATE_PRIMARY_MODE_GIRL_IDS';
const UPDATE_PENDING = 'UPDATE_PENDING';
const UPDATE_MAP_STATE = 'UPDATE_MAP_STATE';
const RESET_MAP_STATE = 'RESET_MAP_STATE';
const RESET_MAP_LOCATION = 'RESET_MAP_LOCATION';

export const updateZoom = (zoom) => ({
  type: UPDATE_ZOOM,
  payload: { zoom },
});

export const updateCenter = (center) => ({
  type: UPDATE_CENTER,
  payload: { center },
});

export const updateGirlsCoordinatesData = (data) => ({
  type: UPDATE_GIRLS_COORDINATES_DATA,
  payload: {
    girls: data.data,
    radiusProfiles: data.radiusProfiles,
  }
})

export const updateGirlCoordinateWithProfile = (profileId) => (dispatch) => {
  const config = {
    params: { ['filter-ids']: profileId },
    quickBooking: true,
    isGetFromState: true,
  }

  dispatch(getActiveDivaGirls(config))
    .then(({ girls }) => {
      if (!girls.length) return;

      dispatch({
        type: UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION,
        payload: {
          profileId,
          data: { 'profile': girls[0] },
        }
      })
    })
}

export const updateGirlCoordinateWithContact = (profileId, callerId) => (dispatch) => {
  dispatch(getContactsById(callerId, CONTACT_TYPES.GIRL, false, false, true))
    .then((contact) => {
      if (!contact) return;

      dispatch({
        type: UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION,
        payload: {
          profileId,
          data: { contact }
        }
      })
    })
}

export const updateClientCoordinates = (place) => ({
  type: UPDATE_CLIENT_DATA,
  payload: {
    longitude: Number(place.longitude) || place.geometry.location.lng(),
    latitude: Number(place.latitude) || place.geometry.location.lat(),
  },
})

export const updateClientRadius = (radius) => ({
  type: UPDATE_CLIENT_DATA,
  payload: { radius },
})

export const resetClientData = () => ({
  type: UPDATE_CLIENT_DATA,
  payload: CLIENT_INITIAL_OBJECT,
})

export const updatePrimaryMode = (mode) => ({
  type: UPDATE_PRIMARY_MODE,
  payload: { mode },
})

export const updateSecondaryMode = (mode, value) => ({
  type: UPDATE_SECONDARY_MODE,
  payload: { mode, value },
})

export const resetSecondaryMode = () => ({ type: RESET_SECONDARY_MODE })

export const updateSecondaryModeIds = (mode, ids) => ({
  type: UPDATE_SECONDARY_MODE_IDS,
  payload: { mode, ids },
})

export const updateSecondaryModeId = (mode, ids) => (dispatch) => {
  const { caller_id, profile_id } = ids instanceof Object ? ids : { profile_id: ids };

  dispatch(updateGirlCoordinateWithProfile(profile_id));
  caller_id && dispatch(updateGirlCoordinateWithContact(profile_id, caller_id));

  dispatch({
    type: UPDATE_SECONDARY_MODE_IDS,
    payload: { mode, ids: profile_id },
  })
}

export const addSecondaryModeId = (mode, ids) => (dispatch) => {
  const { caller_id, profile_id } = ids instanceof Object ? ids : { profile_id: ids };

  dispatch(updateGirlCoordinateWithProfile(profile_id));
  caller_id && dispatch(updateGirlCoordinateWithContact(profile_id, caller_id));

  dispatch({
    type: ADD_SECONDARY_MODE_ID,
    payload: { mode, ids: profile_id },
  })
}

export const removeSecondaryModeId = (mode, id) => ({
  type: REMOVE_SECONDARY_MODE_ID,
  payload: { mode, id },
});

export const updateClientLocation = (location) => ({
  type: UPDATE_CLIENT_LOCATION,
  payload: { location },
})

export const updateMapLocation = (name, value) => ({
  type: UPDATE_MAP_LOCATION,
  payload: { [name]: value },
})

export const resetClientLocation = () => ({
  type: UPDATE_CLIENT_LOCATION,
  payload: { location: '' },
})

export const resetMapLocation = () => ({ type: RESET_MAP_LOCATION })

export const updateFilter = (filter, selectOptions) => ({
  type: UPDATE_FILTER,
  payload: { filter, selectOptions },
})

export const updateActiveGirlIds = (ids) => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.CHAT_ESCORTS]: ids }
})

export const updateFindedGirlId = (id) => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.FINDED_ESCORT]: [id] }
})

export const updateBufferedGirlIds = (ids) => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.BUFFERED_ESCORTS]: ids }
})


export const updateSelectedGirlsIds = (ids = []) => ({
  type: UPDATE_PRIMARY_MODE_PROPS,
  payload: { selectedGirlsIds: ids }
})

export const updateRadiusGirlsIds = (ids = []) => ({
  type: UPDATE_PRIMARY_MODE_PROPS,
  payload: { radiusGirlsIds: ids }
})

export const updatePending = (pending) => ({
  type: UPDATE_PENDING,
  payload: { pending },
});

export const updateMapState = () => ({ type: UPDATE_MAP_STATE });

export const resetFindedGirlId = () => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.FINDED_ESCORT]: null }
});

export const resetMapState = () => ({ type: RESET_MAP_STATE })

const initialState = {
  zoom: DEFAULT_ZOOM,
  center: CENTER_COORDINATES,
  girlsData: {},
  clientData: CLIENT_INITIAL_OBJECT,
  primaryMode: null,
  prevPrimaryMode: null,
  secondaryMode: {},
  girlIdsBySecondaryMode: {},
  location: {
    country: null,
    city: null,
    area: null,
  },
  filters: {},
  filteredGirlIdsByFilters: {},
  updated: false,
  pending: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_MAP_STATE:
      return {
        ...state,
        updated: true,
      }

    case UPDATE_ZOOM:
      return {
        ...state,
        zoom: payload.zoom,
      }

    case UPDATE_CENTER:
      return {
        ...state,
        center: payload.center,
      }
    case UPDATE_GIRLS_COORDINATES_DATA:
      const availableGirlIds = [...state.filteredGirlIdsByFilters[MAP_GIRL_FILTERS.AVAILABLE_ESCORTS] || []];
      const { girls, radiusProfiles } = payload;
      const newUpdateGirlsData = {};
      const convertedGirls = Array.isArray(girls) ? girls : Object.values(girls);
      const convertedRadiusProfiles = Array.isArray(radiusProfiles) ? radiusProfiles : Object.values(radiusProfiles || {});

      // this loop creates marker coordinates withouth duplicates
      for (let i = 0; i < convertedGirls.length; i++) {
        const firstMarker = convertedGirls[i];

        if (firstMarker.profile_id === state.girlIdsBySecondaryMode[MAP_SECONDARY_MODES.TARGET_ESCORT]) continue;

        for (let j = i + 1; j < convertedGirls.length; j++) {
          const secondMarker = convertedGirls[j];

          if (firstMarker.latitude === secondMarker.latitude && firstMarker.longitude === secondMarker.longitude) {
            secondMarker.latitude = parseFloat(secondMarker.latitude) + ((Math.random() * 2 - 1) / 10000);
            secondMarker.longitude = parseFloat(secondMarker.longitude) + ((Math.random() * 2 - 1) / 10000);
          }
        }
      }

      convertedGirls.forEach(girl => {
        if (girl.available_status) {
          availableGirlIds.push(girl.profile_id);
        }

        const convertedGirl = {
          ...girl,
          latitude: Number(girl.latitude),
          longitude: Number(girl.longitude),
        }

        newUpdateGirlsData[girl.profile_id] = { ...state.girlsData[girl.profile_id], girl: convertedGirl };
      });

      convertedRadiusProfiles?.forEach(profile => {
        newUpdateGirlsData[profile.id] = { ...state.girlsData[profile.id], profile };
      });

      return {
        ...state,
        girlsData: { ...state.girlsData, ...newUpdateGirlsData },
        filteredGirlIdsByFilters: {
          ...state.filteredGirlIdsByFilters,
          [MAP_GIRL_FILTERS.AVAILABLE_ESCORTS]: availableGirlIds,
        },
      }
    case UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION:
      const { profileId, data } = payload;

      return {
        ...state,
        girlsData: {
          ...state.girlsData,
          [profileId]: {
            ...state.girlsData[profileId],
            ...data,
          }
        },
      }

    case UPDATE_CLIENT_DATA:
      return {
        ...state,
        clientData: {
          ...state.clientData,
          ...payload,
        },
      }

    case UPDATE_PRIMARY_MODE: {
      return {
        ...state,
        primaryMode: payload.mode,
        prevPrimaryMode: state.primaryMode,
      }
    }

    case UPDATE_SECONDARY_MODE: {
      const { mode, value } = payload;

      return {
        ...state,
        secondaryMode: {
          ...state.secondaryMode,
          [mode]: value ?? !state.secondaryMode[mode],
        },
      }
    }

    case UPDATE_MAP_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          ...payload,
        }
      }

    case RESET_SECONDARY_MODE: {
      return {
        ...state,
        secondaryMode: {},
      }
    }

    case RESET_MAP_LOCATION:
      return {
        ...state,
        location: initialState.location
      }

    case UPDATE_SECONDARY_MODE_IDS: {
      const { mode, ids } = payload;

      return {
        ...state,
        girlIdsBySecondaryMode: {
          ...state.girlIdsBySecondaryMode,
          [mode]: state.girlIdsBySecondaryMode[mode] === ids ? null : ids,
        },
      }
    }

    case ADD_SECONDARY_MODE_ID: {
      const { mode, id } = payload;

      return {
        ...state,
        girlIdsBySecondaryMode: {
          ...state.girlIdsBySecondaryMode,
          [mode]: [...new Set([...(state.girlIdsBySecondaryMode[mode] || []), id])],
        }
      }
    }

    case REMOVE_SECONDARY_MODE_ID: {
      const { mode, id } = payload;

      return {
        ...state,
        girlIdsBySecondaryMode: {
          ...state.girlIdsBySecondaryMode,
          [mode]: state.girlIdsBySecondaryMode[mode].filter(girlId => girlId !== id),
        }
      }
    }

    case UPDATE_PRIMARY_MODE_GIRL_IDS:
      return {
        ...state,
        girlIdsByModes: {
          ...state.girlIdsByModes,
          ...payload,
        },
      }

    case UPDATE_CLIENT_LOCATION:
      return {
        ...state,
        clientData: {
          ...state.clientData,
          location: payload.location,
        },
      }

    case UPDATE_FILTER:
      const { selectOptions = [] } = payload;
      const newFilters = Object.entries(state.filters).reduce((acc, [key, value]) => {
        if (selectOptions.includes(key)) {
          acc[key] = false;
        } else {
          acc[key] = value;
        }

        return acc;
      }, {});

      return {
        ...state,
        filters: {
          ...newFilters,
          [payload.filter]: !state.filters[payload.filter],
        }
      }

    case UPDATE_FILTER_GIRL_IDS:
      return {
        ...state,
        filteredGirlIdsByFilters: {
          ...state.filteredGirlIdsByFilters,
          ...payload,
        },
      }

    case UPDATE_PENDING:
      return {
        ...state,
        pending: payload.pending,
      }

    case RESET_MAP_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}

